import Vue from "vue";
import VueRouter from "vue-router";
import rolesRouter from "../views/roles/rolesRouter";
import profilesRouter from "../views/profiles/profilesRouter";
import usersRouter from "../views/users/userRouter";
import pathsRouter from "../views/paths/pathRouter";

import routerSolicitudes from '../views/solicitudes/routers'
import mantenimientos from "../views/mantenimientos/router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
        children: [
            {
                path: "/",
                name: "login",
                component: () =>
                    import(/* webpackChunkName: "profiles" */ "../views/dashboard"),
            },
            {
                path: "profile",
                name: "profile",
                component: () =>
                    import(/* webpackChunkName: "profiles" */ "../views/profile"),
            },
            ...rolesRouter,
            ...profilesRouter,
            ...usersRouter,
            ...pathsRouter,
            ...routerSolicitudes,
            {
                path: "/security",
                name: "security",
                component: () => import(/* webpackChunkName: "qr" */ "../views/auth/security"),
            },
        ],
    },
    {
        path: "/menu-mantenimiento",
        component: () => import(/* webpackChunkName: "layout" */ "../views/menuMantenimiento"),
        children: [
            {
                path: "/",
                name: "dashboard",
                component: () =>
                    import(/* webpackChunkName: "profiles" */ "../views/dashboard"),
            },
            ...mantenimientos,
        ],
      },
    {
        path: "/inicio",
        name: "inicio",
        component: () => import(/* webpackChunkName: "login" */ "../views/inicio"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/auth/login"),
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
            import(
                /* webpackChunkName: "recuperarPassword" */ "../views/auth/recoverPassword"
                ),
    },
    {
        path: "/reset-password/:id",
        name: "reset-password",
        component: () =>
            import(/* webpackChunkName: "resetPassword" */ "../views/auth/resetPassword"),
    },
    {
        path: "/verify-mail/:token",
        name: "verifyMail",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/auth/verifyMail"),
    },
    {
        path: "/forbidden",
        name: "Forbidden",
        component: () =>
            import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
    },
    {
        path: "/autenticacionqr",
        name: "2fa",
        component: () => import(/* webpackChunkName: "qr" */ "../views/auth/2fa"),
    },
    {
        path: "/menu",
        name: "menu",
        component: () => import(/* webpackChunkName: "menu" */ "../views/menuView"),
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem("token");

    if (to.fullPath === "/forbidden") {
        next("/login");
        return;
    }

    if (!to.matched.length) {
        next("/menu-mantenimiento");
        return;
    }

    if (to.fullPath === "/") {
        next("/menu-mantenimiento");
        if (!token) {
           next("/login");
            return;
        }
    }
    if (await Vue.prototype.canNext(to, token)) {
        next();
    } else {
        next("/forbidden");
    }
});

export default router;
