<template>
  <div>
    <v-app-bar app :elevation="0" color="bgWhite" prominent>
      <v-app-bar-nav-icon class="black--text mt-2" v-if="!sideBar"
        @click.stop="setSideBar(!sideBar)"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="mt-5 d-flex align-center">
        <v-menu offset-y transition="scroll-y-transition" rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" elevation="" text small class="mr-3">
              <v-icon left color="btnAzul--text">mdi-menu-down-outline</v-icon>
              <p class="mr-5 mt-4 font-weight-bold btnAzul--text">
                {{ userInfo?.user?.email }}
              </p>
            </v-btn>
          </template>
          <v-list dense nav>
            <v-list-item @click="openModalCambioPassword()" class="my-0">
              <v-list-item-icon>
                <v-icon size="20">mdi-shield-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Cambiar contraseña</v-list-item-title>
            </v-list-item>
            <v-list-item @click="cerrarSession()" class="my-0">
              <v-list-item-icon>
                <v-icon size="20">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Cerrar sesión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="circular-container" v-show="breakpoint()">
          <v-icon size="50" color="#ffff">mdi-account-outline</v-icon>
        </div>
      </div>
    </v-app-bar>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          Cambio de contraseña
        </v-card-title>

        <v-card-text>
          <v-text-field outlined type="password" label="Contraseña actual *" v-model="form.password" />
          <v-text-field outlined type="password" label="Nueva contraseña *" v-model="form.newPassword" />
          <v-text-field outlined type="password" label="Confirmación de la nueva contraseña *"
            v-model="form.confirmPassword" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="flex flex-row justify-center gap-4">
          <v-btn color="secondary" rounded style="min-width: 35%;" @click="dialog = false"
            v-if="!loading">Cancelar</v-btn>
          <v-btn color="btnAzul" rounded style="min-width: 35%; color: white;" @click="cambiarPassword()"
            :loading="loading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "AppHeader",
  data() {
    return {
      dialog: false,
      form: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      loading: false,
    };
  },
  methods: {
    ...mapMutations("utils", ["setSideBar", "setMenu", "setRoles"]),
    ...mapMutations(["setToken"]),
    async cerrarSession() {
      this.setMenu([]);
      this.setToken(null);
      this.setRoles([]);
      // await this.services.auth.logout()
      localStorage.clear();
      this.$router.push({ name: 'login' }).catch((e) => {
      });
    },
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return false;
      } else if (this.$vuetify.breakpoint.xs) {
        return false;
      } else if (this.$vuetify.breakpoint.md) {
        return true;
      } else if (this.$vuetify.breakpoint.lg) {
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        return true;
      } else {
        return true;
      }
    },

    openModalCambioPassword() {
      this.dialog = true;
    },

    async cambiarPassword() {
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.temporalAlert({
          show: true,
          message: "Las contraseñas no coinciden",
          type: "warning",
        });
        return;
      }
      if (this.form.newPassword.length < 8) {
        this.temporalAlert({
          show: true,
          message: "La contraseña debe tener al menos 8 caracteres",
          type: "warning",
        });
        return;
      }
      if (this.form.password === this.form.newPassword) {
        this.temporalAlert({
          show: true,
          message: "La nueva contraseña no puede ser igual a la actual",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const response = await this.services.auth.changePassword({
        password_actual: this.form.password,
        password: this.form.newPassword,
        confirm_password: this.form.confirmPassword,
      });

      if (response.status === 200) {
        this.dialog = false;
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "success",
        });

        this.form.password = "";
        this.form.newPassword = "";
        this.form.confirmPassword = "";
      } else {
        this.temporalAlert({
          show: true,
          message: 'Error al cambiar la contraseña',
          type: "error",
        });
      }

      this.loading = false;
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("utils", {
      sideBar: "sideBar",
    }),
  },
};
</script>

<style scoped lang="scss">
.btn__hover {
  &:hover {
    background-color: #f4f7fd !important;
  }
}

.icon__color {
  color: #697182 !important;
}

.circular-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1c1e4d;
  /* Fondo azul */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>
